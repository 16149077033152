/* the new packaging needs a 4x6 label, so we need to add a new page size for that */
@page printable-slip-4x6 {
    size: 4in 6in;
    margin: 0;
}

.printable-slip-4x6 {
    page: printable-slip-4x6;
    zoom: 0.75;
}
