@font-face {
    font-family: 'Inter Regular';
    src: url('./Inter/Inter-Regular.otf') format('opentype'),
    url('./Inter/Inter-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter Semibold';
    src: url('./Inter/Inter-SemiBold.otf') format('opentype'),
    url('./Inter/Inter-SemiBold.ttf') format('truetype');
}
