html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-print-color-adjust: exact;
    -moz-osx-font-smoothing: grayscale;
    /* disable the "bouncy" scroll */
    overscroll-behavior-x: none;
    overscroll-behavior-y: none;
    overflow-x: hidden;
    padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

#root {
    display: flex;
    overscroll-behavior-x: none;
    overscroll-behavior-y: none;
}

@media screen and (max-width: 600px) {
    /* no body scroll or bounce on mobile */
    body {
        overflow: hidden;
        position: fixed;
        width: 100%;
    }
    #root {
        flex-direction: row;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@page {
    size: auto;
    margin: 0;
}

tr, td, th, img {
    page-break-inside: avoid;
}

@media print {
    * {
        overflow-y: visible !important;
        max-height: unset !important;
        height: auto;
    }

    #root {
        position: relative !important;
        bottom: unset !important;
        overflow: visible !important;
        height: 100%;
    }

    /* Hide the main page to allow printing lab slips without the nav menus */
    #root.lab-slip-active {
        display: none;
    }
}